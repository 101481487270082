export enum PermissionsCaja {
  VerReporteTrxCierre = 6108,
  VerHistoricoCierresCaja = 6107,
  VerHistoricoNotasDebitoCredito = 6106,
  AgregarNotasCredito = 6105,
  AgregarNotasDebito = 6104,
  AgregarEntidades = 6103,
  AnalizarComprobantes = 6102,
  AgregaComprobantes = 6101,
  RealizarArqueoCierre = 6100,
}
