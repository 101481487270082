/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "us-east-2",
    aws_cognito_region: "us-east-2",
    aws_user_pools_id: "us-east-2_jtr8cWC4d",
    aws_user_pools_web_client_id: "21ofldig7b4ios43hvs3f4c38b",
    oauth: {
      domain: "pdp.auth.us-east-2.amazoncognito.com",
    },
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [
      "NAME",
      "GIVEN_NAME",
      "FAMILY_NAME",
      "MIDDLE_NAME",
      "PHONE_NUMBER",
    ],
    aws_cognito_mfa_configuration: "ON",
    aws_cognito_mfa_types: ["TOTP"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };


export default awsmobile;
