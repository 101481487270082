export const enumPermisosDavivienda = Object.freeze({
  davivienda_cb_cash_in: 5001,
  davivienda_cb_cash_out: 5002,
  davivienda_cb_pago_por_giro: 5003,
  davivienda_cb_depositos: 5004,
  davivienda_cb_retiros: 5005,
  davivienda_cb_recaudo: 5006,
  davivienda_cb_pago_productos_propios: 5007,
  davivienda_cb_recaudo_operaciones: 5008,
});
